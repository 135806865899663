import "reflect-metadata";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import ShellView from './Presentation/ShellView';
import { Application, TranslationManager, HttpManager, Managers, LanguageManager, NavigationManager, PopUpManager, AuthManager, StorageManager, ModuleManager, ToastManager} from '@levelapp/softfabric';
import { UiManagers, StyleManager } from '@levelapp/softfabric-ui';
import PathConstants from "./Common/Constants/PathConstants";

export default class App extends Application
{
  initialisation()
  {
    Application.current = this;

    

    this.register(Managers.NAVIGATION_MANAGER, new NavigationManager());
    //Current language && Array Accepted Languages
    this.register(Managers.LANGUAGE_MANAGER,new LanguageManager("en-GB",["en-GB"]));



    //Registrer The Translation Manager with a path to the translation.json
    this.register(Managers.TRANSLATION_MANAGER,new TranslationManager(require('./Translations/Translations.json')));

    //To modify the theme of your app. Specify a new Style Manager.
    //Create a ExtendedManager extends StyleManager 
    this.register(UiManagers.STYLE_MANAGER, new StyleManager());

    var storage =  new StorageManager();
    this.register(Managers.STORAGE_MANAGER , storage);

    var auth = new AuthManager("","","",storage);
    this.register(Managers.AUTH_MANAGER , auth);

    //Set a httpClient with an url and a callback
    this.register(Managers.HTTP_MANAGER, new HttpManager(PathConstants.SERVICES,
      async () =>
    {
      // let auth = this.resolve<AuthManager>(Managers.AUTH_MANAGER);
      // let token = await auth.getTokenFromDisk();
      // if (token && token.access_token)
      // {
      //   return "Bearer" + ' ' + token.access_token;
      // }

      // else
      // {
      //   return '';
      // }
    },auth));

    //Set the PopUp Manager
    this.register(Managers.POPUP_MANAGER, new PopUpManager());

    this.register(Managers.MODULE_MANAGER,  new ModuleManager());
    this.register(Managers.TOAST_MANAGER , new ToastManager());

    //Set a callback for handling errors differents than 404 and 401
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleSomethingWentWrong = this.handleSomethingWentWrong;
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).handleUnauthorized = this.handleUnauthorized;
    this.resolve<HttpManager>(Managers.HTTP_MANAGER).addCustomHeader("tenant-id", 'ba689285-90c4-44ef-aa13-14b1372678a4')
    this.resolve<AuthManager>(Managers.AUTH_MANAGER).handleNoTokenFound = this.handleNoTokenFound;
  }


  handleSomethingWentWrong()
  {
    //Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleError()
  {
    //Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleNoTokenFound()
  {
    Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  handleUnauthorized()
  {
    Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER).navigate('/login');
  }

  renderApplication()
  {
    return (<ShellView />);

  }
}